<template>
  <div class="container">
    <Carousel :args="args.slide" />
    <Statistics />
    <Course :args="args.course" />
    <Software :args="args.software" />
    <News :args="args.news" />
    <OnlineCourse :args="args.online_course" />
    <About :args="args.about" />
  </div>
</template>

<script>
import Carousel from "@/components/common/carousel.vue";
import Statistics from "@/components/home/statistics.vue";
import Course from "@/components/home/course/index.vue";
import Software from "@/components/home/software.vue";
import News from "@/components/home/news.vue";
import OnlineCourse from "@/components/home/online_course/index.vue";
import About from "@/components/home/about.vue";
export default {
  components: {
    Carousel,
    Statistics,
    Course,
    Software,
    News,
    OnlineCourse,
    About,
  },
  created() {
    this.$api("/official/page/home").then(({ data }) => {
      this.args = data;
    });
  },
  data() {
    return {
      args: {},
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 1440px;
}
</style>

