<template>
  <div class="container">
    <div class="content">
      <img :src="item.pic" class="cover" @click="skipInfo">
      <div class="title" @click="skipInfo">{{item.name}}</div>
      <div class="button" @click="skipInfo">
        详情
        <SvgIcon name="next" class="icon-next" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    skipInfo() {
      this.$router.push({
        path: "/textbook_info",
        query: { id: this.item.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 305px;
  height: 350px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.11);
  border-radius: 16px;
  overflow: hidden;

  .content {
    .cover {
      width: 305px;
      height: 225px;
      cursor: pointer;
      &:hover {
        transition: 0.5s;
        transform: scale(1.05);
      }
    }

    .title {
      cursor: pointer;
      font-size: $font-size-18;
      color: $font-color-black;
      padding-left: 15px;
      margin-top: 22px;
      font-weight: bold;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // &:hover {
      //   padding-left: 20px;
      //   transition: 0.5s;
      //   transform: scale(1.05);
      // }
    }

    .button {
      cursor: pointer;
      margin-left: 15px;
      margin-top: 12px;

      width: 87px;
      height: 36px;
      border-radius: 18px;
      border: 1px solid #ff7920;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 20px;
      color: #ff761f;
      background-image: none;

      .icon-next {
        margin-top: 3px;
        margin-left: 5px;
        width: 8px;
        height: 16px;
        fill: $font-color-orange;
      }
      &:hover {
        transition: 0.5s;
        transform: scale(1.05);
      }
    }
  }
}
</style>