<template>
  <div class="news-container" v-if="news.length">
    <div class="top">
      <div class="main-title">新闻<font> / NEWS</font>
      </div>
      <div class="sub-title">企业动态 及时报道</div>
    </div>
    <div class="body">
      <img :src="news[itemIndex].cover" :class="{cover:true, actived:picActived}" />
      <div class="info">
        <ul class="news">
          <li @mouseover="newHoverHanlder(idx)" v-for="(item, idx) in news" :key="idx">
            <div :class="{title:true, actived:idx==itemIndex}">{{item.title}}</div>
            <div class="bottom">
              <div class="time">{{dateFormat(item.issue_time, "y / m / d")}}</div>
              <div class="more" @click="skipInfo(item.id)">
                更多
                <SvgIcon name="next" class="icon-next" />
              </div>
              <!-- <div class="button" @click="skipInfo(item.id)">查看详情</div> -->
            </div>
          </li>
        </ul>
        <div class="button" @click="skipNews">
          更多
          <SvgIcon name="next" class="icon-next" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      picActived: true,
      itemIndex: 0,
      news: [],
    };
  },
  props: {
    args: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    args(items) {
      this.news = items;
    },
  },
  methods: {
    skipNews() {
      this.$router.push({ path: "/news" });
    },
    skipInfo(id) {
      this.$router.push({ path: "/news_info", query: { id: id } });
    },
    newHoverHanlder(idx) {
      this.picActived = false;

      setTimeout(() => {
        this.picActived = true;
        this.itemIndex = idx;
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
.news-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 81px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
    .sub-title {
      margin-top: 10px;
      font-size: $font-size-16;
      color: $font-color-gray;
    }
  }

  .body {
    margin-top: 50px;
    display: flex;

    .cover {
      width: 815px;
      height: 515px;
      box-shadow: 0px 2px 25px 0px rgba(234, 77, 0, 0.2);
      border-radius: 26px;
      overflow: hidden;
      transition: 0.25s;
      visibility: hidden;
      // opacity: 0.9;

      &.actived {
        visibility: visible;
        opacity: 1;
      }
    }
    .info {
      margin-left: 50px;
      width: 240px;
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      .news {
        list-style: none;

        li {
          margin-top: 24px;
          user-select: none;

          &:first-child {
            margin-top: 10px;
          }

          .title {
            font-size: $font-size-20;
            font-weight: bold;

            &.actived,
            &:hover {
              color: $font-color-orange;
            }
          }

          .bottom {
            margin-top: 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > .time {
              color: $font-color-gray;
              font-size: $font-size-14;
            }

            > .more {
              cursor: pointer;

              color: $font-color-gray;
              font-size: $font-size-14;

              display: flex;
              align-items: center;
              justify-content: center;

              .icon-next {
                width: 14px;
                height: 10px;
              }
            }
          }
        }
      }

      > .button {
        margin-top: auto;
        margin-left: auto;

        width: 87px;
        height: 35px;
        border-radius: 18px;
        border: 1px solid #ff7920;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff761f;
        line-height: 29px;

        .icon-next {
          margin-left: 5px;
          width: 8px;
          height: 15px;
        }

        cursor: pointer;

        &:hover {
          transition: 0.5s;
          transform: scale(1.06);
        }
      }
    }
  }
}
</style>