<template>
  <div class="container">
    <div class="top">
      <div class="main-title">网课<font> / ONLINE COURSE</font>
      </div>
      <div class="sub-title">优师主讲 生动有趣</div>
    </div>
    <div class="bottom">
      <div class="bottom-head">
        <p>
          <!-- 班型：一对一 一对多<br />
          类别：直播课 录播课<br />
          阶段：幼儿阶 少儿阶<br /> -->
          <br />

          适合：4岁-12岁 <br />
          类别：音视频课 <br />
          <!-- 班制：一对一 / 一对二 / 一对四<br> -->
          <!-- 类别：直播课 / 录播课 / 音频课<br> -->
          <!-- 班型：幼儿 / 小学/ 初中 / 艺考<br> -->
        </p>
        <img src="/static/img/index/online_course/people.png" />
      </div>
      <div class="bottom-body">
        <div class="online-course">
          <el-carousel ref="carousel" @change="changeCarouselHandle" indicator-position="none" arrow="never"
            height="100%" :interval="5000">
            <el-carousel-item class="items" v-for="(idx2, key2) in coursesPage" :key="key2">
              <CourseItem :class="{ item: true, not: !carouselList[idx3] }"
                v-for="(idx3, key3) in range(idx2 * 3 - 3, idx2 * 3 - 1)" :key="key3" :item="carouselList[idx3]" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="indicator">
          <div @click="changeCarouselIndex(key2)" :class="{ point: true, actived: carouselIndicatorIndex == key2 }"
            v-for="(idx2, key2) in coursesPage" :key="key2"></div>
        </div>
        <div class="more" @click="skipOnlineCourse">
          更多
          <SvgIcon name="next" class="icon-next" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseItem from "./course_item.vue";
export default {
  components: {
    CourseItem,
  },
  computed: {
    coursesPage() {
      return Math.ceil(this.carouselList.length / 3);
    },
  },
  data() {
    return {
      itemIndex: 0,
      carouselIndicatorIndex: 0,
      carouselList: [],
    };
  },
  props: {
    args: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    args(items) {
      this.carouselList = items;
    },
  },
  methods: {
    skipOnlineCourse() {
      this.$router.push({ path: "/online_course" });
    },
    changeCarouselHandle(idx) {
      this.carouselIndicatorIndex = idx;
    },
    range(start, end, step = 1) {
      let items = [];
      for (let i = start; i <= end; i = i + step) {
        items.push(i);
      }
      return items;
    },
    selectTab(idx) {
      if (this.carouselIndex == idx) {
        return;
      }
      this.carouselShow = false;
      setTimeout(() => {
        this.carouselIndex = idx;
        setTimeout(() => {
          this.carouselShow = true;
        }, 250);
      }, 50);
    },
    changeCarouselIndex(index) {
      if (index > this.coursesPage - 1) {
        index = 0;
      }
      if (index < 0) {
        index = this.coursesPage - 1;
      }
      this.carouselIndicatorIndex = index;
      this.$refs.carousel.setActiveItem(this.carouselIndicatorIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 80px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
    .sub-title {
      margin-top: 10px;
      font-size: $font-size-16;
      color: $font-color-gray;
    }
  }

  .bottom {
    width: 1440px;
    margin-top: 365px;
    background: linear-gradient(151deg, #ffa83a 0%, #ff6f1b 100%);
    height: 662px;

    .bottom-head {
      p {
        font-size: 22px;
        color: #ffffff;
        line-height: 36px;
        margin: 57px 0 0 136px;
        user-select: none;
      }
      img {
        position: absolute;
        top: 195px;
        right: 131px;
        width: 870px;
        height: 690px;
        user-select: none;
      }
    }

    .bottom-body {
      // margin: 32px auto 0 auto;
      margin: 40px auto 0 auto;

      width: 1180px;
      height: 400px;
      position: relative;
      z-index: 99;
      background-color: #fff;

      box-shadow: 0px 2px 37px 0px rgba(111, 0, 0, 0.2);
      border-radius: 16px;

      .online-course {
        width: 1180px;
        height: 400px;

        .el-carousel {
          height: 100%;
          // overflow-y: hidden;

          .items {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            // padding-top: 55px;

            .item {
              cursor: default;
              margin: 0 17.5px;
              // &:hover {
              //   transition: 0.5s;
              //   transform: scale(1.025);
              //   margin: 0 35px;
              // }

              &.not {
                visibility: hidden;
              }
            }
          }
        }
      }

      .more {
        position: absolute;
        right: 64px;
        bottom: 15px;
        width: 84px;
        height: 33px;
        border-radius: 17px;
        border: 1px solid #ff6f1b;
        color: #ff6f1b;

        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        cursor: pointer;
        z-index: 999;

        .icon-next {
          margin-left: 2px;
          width: 8px;
          height: 20px;
        }

        &:hover {
          transition: 0.5s;
          transform: scale(1.06);
        }
      }

      .indicator {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        .point {
          width: 10px;
          height: 10px;
          background-color: rgba($color: $bg-color-orange, $alpha: 0.1);
          margin: 0 5px 0 5px;
          border-radius: 100%;
          transition: 0.5s;
          cursor: pointer;
          z-index: 999;

          &:hover {
            transform: scale(1.25);
          }

          &.actived {
            background-color: rgba($color: $bg-color-orange, $alpha: 1);
          }
        }
      }
    }
  }
}
</style>