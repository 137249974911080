<template>
  <div class="container" v-show="args.length">
    <div class="top">
      <div class="main-title">课程<font>/COURSE</font>
      </div>
      <div class="sub-title">演播体系 原创研发</div>
    </div>
    <div class="tab">
      <ul>
        <li @click="selectTab(0)" :class="{actived:carouselIndex==0}">
          <img class="studio" v-if="carouselIndex==0" src="/static/img/index/course/icon-studio-selected.png">
          <img class="studio" v-else src="/static/img/index/course/icon-studio-unselect.png">
          演播课程
        </li>
        <li v-if="carouselList[1].length" @click="selectTab(1)" :class="{actived:carouselIndex==1}">
          <img class="spoken" v-if="carouselIndex==1" src="/static/img/index/course/icon-spoken-selected.png">
          <img class="spoken" v-else src="/static/img/index/course/icon-spoken-unselect.png">
          思维口语
        </li>
        <li @click="selectTab(2)" :class="{actived:carouselIndex==2}">
          <img class="feature" v-if="carouselIndex==2" src="/static/img/index/course/icon-feature-selected.png">
          <img class="feature" v-else src="/static/img/index/course/icon-feature-unselect.png">
          精品课程
        </li>
      </ul>
    </div>
    <div class="tab-items">
      <div :class="{carousel:true, actived:carouselShow}">
        <el-carousel ref="carousel" @change="changeCarouselHandle" indicator-position="none" arrow="never" height="100%"
          :interval="5000">
          <el-carousel-item class="items" v-for="(idx2, key2) in coursesPage" :key="key2">
            <CourseItem v-show="courses[idx3]" class="item"
              v-for="(idx3, key3) in range((idx2 * 3 - 3), (idx2 * 3 - 1))" :key="key3" :item="courses[idx3]" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="action">
        <div class="pre" @click="changeCarouselIndex(carouselIndicatorIndex - 1)"></div>
        <div class="next" @click="changeCarouselIndex(carouselIndicatorIndex + 1)"></div>
      </div>
      <div :class="{indicator:true, actived:carouselShow}">
        <div @click="changeCarouselIndex(key2)" :class="{point:true, actived:carouselIndicatorIndex == key2}"
          v-for="(idx2, key2) in coursesPage" :key="key2">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseItem from "./course_item.vue";
export default {
  components: {
    CourseItem,
  },
  computed: {
    coursesPage() {
      return Math.ceil(this.courses.length / 3);
    },
    courses() {
      return this.carouselList[this.carouselIndex];
    },
  },
  props: {
    args: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      carouselShow: true,
      carouselIndicatorIndex: 0,
      carouselIndex: 0,
      carouselList: {
        0: [],
        1: [],
        2: [],
      },
    };
  },
  watch: {
    args(items) {
      console.log(items);
      items.forEach((item) => {
        this.carouselList[item.category].push(item);
      });
    },
  },
  methods: {
    changeCarouselHandle(idx) {
      this.carouselIndicatorIndex = idx;
    },
    range(start, end, step = 1) {
      let items = [];
      for (let i = start; i <= end; i = i + step) {
        items.push(i);
      }
      return items;
    },
    selectTab(idx) {
      if (this.carouselIndex == idx) {
        return;
      }
      this.carouselShow = false;
      setTimeout(() => {
        this.carouselIndex = idx;
        setTimeout(() => {
          this.carouselShow = true;
        }, 250);
      }, 50);
    },
    changeCarouselIndex(index) {
      console.log(index);
      if (index > this.coursesPage - 1) {
        index = 0;
      }
      if (index < 0) {
        index = this.coursesPage - 1;
      }
      this.carouselIndicatorIndex = index;
      this.$refs.carousel.setActiveItem(this.carouselIndicatorIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 148px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
    .sub-title {
      margin-top: 10px;
      font-size: $font-size-16;
      color: $font-color-gray;
    }
  }

  .tab {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    ul {
      display: flex;

      li {
        position: relative;
        z-index: 999999;

        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 174px;
        height: 48px;
        font-size: 16px;
        color: $font-color-orange;
        border: 1px solid $border-color-orange;
        cursor: pointer;

        &:nth-child(n + 1) {
          border-left: none;
        }
        &:first-child {
          border-radius: 26px 0px 0px 26px;
        }
        &:last-child {
          border-radius: 0px 26px 26px 0px;
        }

        img {
          margin-right: 4px;

          &.studio {
            width: 20px;
            height: 26px;
          }
          &.spoken {
            width: 30px;
            height: 16px;
          }
          &.feature {
            width: 23px;
            height: 25px;
          }
        }

        transition: 0.5s;

        &:hover {
          position: relative;
          z-index: 99;
          // border: 1px solid $border-color-orange;
          // transform: scale(1.1);
          // padding: 0 10px;
          // background: #ffa83a;
          // color: $font-color-white;
        }

        &.actived {
          background-image: $bg-color-orange-vertical-linear-gradient;
          color: $font-color-white;
        }
      }
    }
  }

  .tab-items {
    padding: 0 228px 0 228px;
    position: relative;

    .carousel {
      // height: 435px;
      // height: 455px;
      height: 500px;

      visibility: hidden;
      opacity: 0;

      &.actived {
        visibility: visible;
        opacity: 1;
        transition: 1s;
      }

      .el-carousel {
        height: 100%;
        overflow-y: hidden;

        .items {
          display: flex;
          height: 100%;
          padding-top: 55px;

          .item {
            margin: 0 17px;
            // cursor: pointer;
            &:hover {
              transition: 0.5s;
              transform: scale(1.05);
              //   // margin: 0 34px;
            }
          }
        }
      }
    }

    .action {
      position: absolute;
      left: 130px;
      right: 130px;
      bottom: 0;
      top: -50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .pre,
      .next {
        width: 45px;
        height: 45px;

        background-size: 100%;
        opacity: 0.2;
        transition: 0.25s;

        &:hover {
          transform: scale(1.25);
          opacity: 1;
        }
      }
      .pre {
        background-image: url(/static/img/index/course/icon-pre.png);
      }
      .next {
        background-image: url(/static/img/index/course/icon-next.png);
      }
    }
  }

  .indicator {
    position: relative;
    z-index: 999;

    display: flex;
    justify-content: center;

    visibility: hidden;
    opacity: 0;

    &.actived {
      visibility: visible;
      opacity: 1;
      transition: 1s;
    }

    .point {
      width: 10px;
      height: 10px;
      background-color: rgba($color: $bg-color-orange, $alpha: 0.1);
      margin: 0 5px 0 5px;
      border-radius: 100%;
      transition: 0.5s;
      cursor: pointer;

      &:hover {
        transform: scale(1.25);
      }

      &.actived {
        background-color: rgba($color: $bg-color-orange, $alpha: 1);
      }
    }
  }
}
</style>