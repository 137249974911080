<template>
  <div class="container">
    <div class="top">
      <div class="main-title">软件<font> / SOFTWARE</font>
      </div>
      <div class="sub-title">校管网课 轻松方便</div>
    </div>
    <div class="bottom">
    </div>
    <img src="/static/img/index/software/people.png?v=1" class="people" />
    <img class="logo e_animate_pulse" src="/static/img/index/software/logo.png?v=1">

    <div class="button download" @click="showAppQrcode">点击下载</div>
    <div class="button join" @click="showGrouponAppletQrcode">点击加入</div>

    <div class="float" v-show="float">
      <div class="content">
        <div class="title">
          <img src="/static/img/about/mascot/title_bg.png" />
          <div class="text">扫码下载</div>
        </div>
        <div class="qrcode-block">
          <div class="qrcode">
            <img :src="qrcode" />
          </div>
        </div>
        <div @click="float = false;" class="close">
          <img @mouseenter="closeMourseOverHandler" v-show="!closeHover"
            src="/static/img/about/mascot/icon_close.png" />
          <img @mouseleave="closeMourseLeaveHandler" v-show="closeHover"
            src="/static/img/about/mascot/icon_close_hover.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      float: false,
      closeHover: false,
      qrcode: "",
    };
  },
  props: {
    args: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    showAppQrcode() {
      this.float = true;
      this.qrcode = this.args.app_qrcode;
    },
    showGrouponAppletQrcode() {
      this.float = true;
      this.qrcode = this.args.groupon_applet_qrcode;
    },
    closeMourseOverHandler() {
      this.closeHover = true;
    },
    closeMourseLeaveHandler() {
      this.closeHover = false;
    },
    newHoverHanlder(idx) {
      this.picActived = false;

      this.itemIndex = idx;
      setTimeout(() => {
        this.picActived = true;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes pulse {
  0% {
    transform: scale(2);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(2);
  }
}
.e_animate_pulse {
  animation: pulse 1.5s 600ms linear infinite;
}
.container {
  position: relative;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 144px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
    .sub-title {
      margin-top: 10px;
      font-size: $font-size-16;
      color: $font-color-gray;
    }
  }

  .bottom {
    margin-top: 516px;
    background: linear-gradient(307deg, #ffa83a 0%, #ff6f1b 100%);
    height: 497px;
  }

  .people {
    position: absolute;
    // top: 212px;
    bottom: 0px;
    left: 94px;
    width: 1100px;
    height: 963px;
  }

  .logo {
    width: 32px;
    height: 32px;
    z-index: 2;
    position: absolute;
    bottom: 340px;
    left: 733.5px;
    // transform: rotate(4deg);
  }

  .button {
    width: 174px;
    height: 48px;
    background: linear-gradient(180deg, #f6d365 0%, #ff8f3c 100%);
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $font-color-white;
    font-size: $font-size-16;
    cursor: pointer;

    &:hover {
      color: #ff8f3c;
      background: none;
      transition: 1s;
      background-color: white;
      font-weight: bold;
    }

    &.download {
      position: absolute;
      z-index: 999;
      top: 638px;
      right: 338px;
      border: 1px solid white;

      &:hover {
        border: 1px solid $border-color-orange;
      }
    }
    &.join {
      position: absolute;
      z-index: 999;
      top: 1126px;
      right: 338px;
    }
  }

  .float {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999999;
    background-color: rgba($color: #000000, $alpha: 0.5);

    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      width: 485px;
      height: 400px;
      background: #ffffff;
      border-radius: 64px;
      position: relative;

      .close {
        width: 78px;
        height: 78px;
        cursor: pointer;

        position: absolute;
        z-index: 9999999999;
        right: -5px;
        top: -5px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        z-index: 99999;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 299px;
          height: 134px;
        }

        .text {
          position: absolute;
          top: 45px;
          left: 0;
          right: 0;
          margin-left: 30px;

          font-size: 25px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .qrcode-block {
        position: absolute;
        top: 103px;
        left: 0;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        .qrcode {
          width: 250px;
          height: 250px;
          background: #f5f5f5;
          border-radius: 20px;

          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 182px;
            height: 182px;
            background: #fff;
            border-radius: 13px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>