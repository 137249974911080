<template>
  <div class="online-course-item-container">
    <img :src="item.pic" class="cover" @click="skipInfo">
    <div class="title" @click="skipInfo">{{item.name}}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    skipInfo() {
      // const id = this.item.id;
      // this.$router.push({ path: "/online_course_info", id: id });
    },
  },
};
</script>

<style lang="scss" scoped>
.online-course-item-container {
  width: 327px;
  height: 270px;
  cursor: pointer;

  .cover {
    width: 327px;
    height: 235px;
    border-radius: 16px;
  }

  .title {
    font-size: $font-size-18;
    color: $font-color-black;
    margin-top: 10px;
    font-weight: bold;
    font-family: PingFangSC-Medium, PingFang SC;
    align-self: flex-start;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>